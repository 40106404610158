import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { FREESTAR_ENABLED } from 'src/common/constants/experiments/ads';
import useExperiment from 'src/common/hooks/useExperiment';
import usePrevious from 'src/common/hooks/usePrevious';
import {
  getDisplayAdAttributes,
  gptSlotNames,
} from 'src/common/utils/ads/constants';
import rootCategoriesData from '../../../data/rootCategories.json';
import { fetchCategory } from '../../actions/browse';
import { logClientError } from '../../actions/logging';
import connectWithFailureState from '../../decorators/connectWithFailureState';
import useActions from '../../hooks/useActions';
import useBrazeContentCards from '../../hooks/useBrazeContentCards';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import { selectIsFord } from '../../selectors/app';
import { selectIsAuthenticated } from '../../selectors/auth';
import css from '../../styles/common.module.scss';
import { isSmall, isXLarge, isXXLarge } from '../../utils/breakpoints';
import { buildGuideItemPathnameWithLogger } from '../../utils/guideItem/getGuideItemPathname';
import getStoreGuideId from '../../utils/guideItem/getStoreGuideId';
import SeoHelmet from '../SeoHelmet';
import Title from '../Title';
import DisplayAd from '../ads/DisplayAd';
import SponsoredRollupProvider from '../ads/SponsoredRollupProvider';
import ContainerItemsLoader from '../containerItems/ContainerItemsLoader';
import canonicalLinkObj from '../utils/canonicalLinkObj';
import { createAndroidDeepLinks } from '../utils/createAndroidDeepLinks';
import { createInternationalSeoLinks } from '../utils/createInternationalSeoLinks';
import { createBrowsePageMetaDescription } from '../utils/pageMetaDescriptions';
import { createBrowsePageMetaKeywords } from '../utils/pageMetaKeywords';
import { createBrowsePageTitle } from '../utils/pageTitles';

const { guideIdsToPathnames: rootCategories } = rootCategoriesData;

const guideIdMetaMap = {
  [rootCategories.recents]: [{ name: 'robots', content: 'noindex' }],
};

function getMetaData(
  getLocalizedText,
  categoryGuideItem = {},
  logClientErrorAction,
) {
  const { seoInfo, guideInfo } = categoryGuideItem.metadata?.properties || {};

  const title = seoInfo?.title || guideInfo?.title || categoryGuideItem.title;
  const description =
    seoInfo?.description ||
    guideInfo?.description ||
    categoryGuideItem.description;
  const links = [
    canonicalLinkObj(
      buildGuideItemPathnameWithLogger(categoryGuideItem)(logClientErrorAction),
    ),
    ...createInternationalSeoLinks(
      buildGuideItemPathnameWithLogger(categoryGuideItem)(logClientErrorAction),
    ),
    ...createAndroidDeepLinks(categoryGuideItem.guideId),
  ];

  return {
    title: createBrowsePageTitle({
      getLocalizedText,
      title,
      guideId: categoryGuideItem.guideId,
      properties: categoryGuideItem.metadata?.properties,
    }),
    description: createBrowsePageMetaDescription({
      getLocalizedText,
      guideId: categoryGuideItem.guideId,
      title,
      description,
    }),
    keywords: createBrowsePageMetaKeywords({
      title,
      keywords: seoInfo?.keywords,
    }),
    links,
    meta: guideIdMetaMap[categoryGuideItem.guideId],
  };
}

function Browse({
  isFetching,
  categoryGuideItem,
  breakpoint,
  routeProps,
  selectedGuideId,
  canShowAds,
  isDiscord,
}) {
  const { getLocalizedText } = useContext(LocationAndLocalizationContext);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const prevIsAuthenticated = usePrevious(isAuthenticated);
  const { logClientError: logClientErrorAction } = useActions({
    logClientError,
  });
  const isAuthenticationChanged =
    prevIsAuthenticated !== isAuthenticated &&
    typeof prevIsAuthenticated !== 'undefined';
  const isFord = useSelector(selectIsFord);
  const isFreestarEnabled = useExperiment(FREESTAR_ENABLED);

  if (!isFord) {
    // biome-ignore lint/correctness/useHookAtTopLevel: isFord is static, so we can break React's Rules of Hooks
    useBrazeContentCards(categoryGuideItem, isAuthenticationChanged);
  }

  if (isAuthenticationChanged) {
    dispatch(fetchCategory(routeProps?.guideContext));
  }

  if (!categoryGuideItem?.guideId) {
    return null;
  }

  const showDisplayAd =
    canShowAds &&
    ((isSmall(breakpoint) && !isXLarge(breakpoint)) || isXXLarge(breakpoint));
  const { properties, styles: itemStyles = {} } =
    categoryGuideItem?.metadata || {};
  const categoryPageTitleImage = properties?.titleImage?.imageUrl;

  return (
    <div
      className={css.contentContainer}
      data-testid={`categoryPage-${selectedGuideId}`}
    >
      <SeoHelmet
        {...getMetaData(
          getLocalizedText,
          categoryGuideItem,
          logClientErrorAction,
        )}
      />
      {showDisplayAd && (
        <DisplayAd
          style={{ marginBottom: '45px' }}
          guideId={routeProps.guideContext?.guideId}
          matchUrl={routeProps.matchUrl}
          {...getDisplayAdAttributes(
            gptSlotNames.browse_top,
            isFreestarEnabled,
          )}
        />
      )}
      {(categoryGuideItem.title || categoryPageTitleImage) && (
        <Title
          pageTitle={categoryGuideItem.title}
          pageTitleImage={categoryPageTitleImage}
          guideId={categoryGuideItem.guideId}
          isHidden={categoryGuideItem.isTitleHidden || isDiscord}
        />
      )}
      <SponsoredRollupProvider guideId={selectedGuideId}>
        <ContainerItemsLoader
          guideItem={categoryGuideItem}
          items={categoryGuideItem.containerItems}
          itemsStyles={itemStyles}
          isFetching={isFetching}
          selectedContentId={selectedGuideId}
          routeProps={routeProps}
          breakpoint={breakpoint}
          className={css.containerItemsWrapper}
        />
      </SponsoredRollupProvider>
    </div>
  );
}

Browse.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  categoryGuideItem: PropTypes.object.isRequired,
  breakpoint: PropTypes.number.isRequired,
  routeProps: PropTypes.object.isRequired,
  selectedGuideId: PropTypes.string.isRequired,
  canShowAds: PropTypes.bool.isRequired,
  isDiscord: PropTypes.bool.isRequired,
};

function getGuideId(state, props) {
  const { attributes, guideId } = props?.routeProps?.guideContext || {};
  return getStoreGuideId(guideId, attributes);
}

function getCategoryGuideItem(state, props) {
  const storeGuideId = getGuideId(state, props);
  return state.categories[storeGuideId];
}

export function mapStateToProps(state, ownProps) {
  const storeGuideId = getGuideId(state, ownProps);
  const categoryGuideItem = state.categories[storeGuideId];

  return {
    selectedGuideId: storeGuideId,
    categoryGuideItem,
    isFetching: categoryGuideItem?.isFetching || false,
  };
}

export default flow(
  connectWithFailureState(getCategoryGuideItem),
  connect(mapStateToProps),
)(Browse);
