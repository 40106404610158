import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  logContentCardFailures,
  logReceivedDuplicatedCards,
  updateCardValidationErrors,
} from 'src/common/actions/logging';
import usePrevious from 'src/common/hooks/usePrevious';
import { setBrazeCategoryCards } from '../actions/browse';
import { setBrazeProfileCards } from '../actions/profile';
import { selectIsBrazeInitialized } from '../selectors/app';
import { getSelectCategory } from '../selectors/categories';
import {
  selectBrazeContentCardsRefreshDelaySeconds,
  selectIsBrazeContentCardsEnabled,
  selectIsBrazeContentCardsRemoveDuplicatesEnabled,
} from '../selectors/config';
import { selectCardsValidationErrors } from '../selectors/logging';
import { selectIsUserSubscribed } from '../selectors/me';
import { getSelectProfile } from '../selectors/profiles';
import {
  brazeRequestContentCardsRefresh,
  getBrazeContentCards,
} from '../utils/braze/brazeHelper';
import { isProfile } from '../utils/guideItemTypes';
import useActions from './useActions';

export default function useBrazeContentCards(
  guideItem = {},
  isAuthenticationChanged,
) {
  const { guideId } = guideItem || {};
  const [brazeCards, setBrazeCards] = useState();
  const isProfileGuideId = isProfile(guideId);
  const profileGuideItem = useSelector(getSelectProfile(guideId));
  const categoryGuideItem = useSelector(getSelectCategory(guideId));
  const actions = useActions({
    setBrazeProfileCards,
    setBrazeCategoryCards,
    logContentCardFailures,
    logReceivedDuplicatedCards,
    updateCardValidationErrors,
  });
  const isBrazeContentCardsExperimentEnabled = useSelector(
    selectIsBrazeContentCardsEnabled,
  );
  const isBrazeContentCardsRemoveDuplicatesEnabled = useSelector(
    selectIsBrazeContentCardsRemoveDuplicatesEnabled,
  );
  const brazeContentCardsRefreshDelaySeconds = useSelector(
    selectBrazeContentCardsRefreshDelaySeconds,
  );
  const isBrazeInitialized = useSelector(selectIsBrazeInitialized);
  const cardValidationErrors = useSelector(selectCardsValidationErrors);
  const isUserSubscribed = useSelector(selectIsUserSubscribed);
  const prevIsUserSubscribed = usePrevious(isUserSubscribed);
  const isUserSubscribedChanged = prevIsUserSubscribed !== isUserSubscribed;
  const didDispatchBrazeCards = useRef();

  const fetchCards = useCallback(async () => {
    try {
      const brazeCardItems = await getBrazeContentCards(
        guideId,
        isUserSubscribed,
        actions.updateCardValidationErrors,
        actions.logReceivedDuplicatedCards,
        categoryGuideItem?.containerItems?.length,
        categoryGuideItem?.metadata?.properties?.seoInfo,
        isBrazeContentCardsRemoveDuplicatesEnabled,
      );

      if (!brazeCardItems) {
        return;
      }

      setBrazeCards({
        guideId,
        items: brazeCardItems,
      });
    } catch (error) {
      // uncomment if we need to do further debugging with sentry
      // for now we don't want to log this error to sentry
      // Sentry.captureException(error);
    }
  }, [
    actions.updateCardValidationErrors,
    actions.logReceivedDuplicatedCards,
    categoryGuideItem?.containerItems?.length,
    categoryGuideItem?.metadata?.properties?.seoInfo,
    guideId,
    isUserSubscribed,
    isBrazeContentCardsRemoveDuplicatesEnabled,
  ]);

  useEffect(() => {
    if (
      isBrazeInitialized &&
      isBrazeContentCardsExperimentEnabled &&
      (isAuthenticationChanged || isUserSubscribedChanged)
    ) {
      brazeRequestContentCardsRefresh(
        isUserSubscribedChanged,
        brazeContentCardsRefreshDelaySeconds,
      );
    }
  }, [
    brazeContentCardsRefreshDelaySeconds,
    isAuthenticationChanged,
    isBrazeContentCardsExperimentEnabled,
    isBrazeInitialized,
    isUserSubscribedChanged,
  ]);

  useEffect(() => {
    didDispatchBrazeCards.current = false;

    if (isBrazeInitialized && isBrazeContentCardsExperimentEnabled) {
      fetchCards();
    }
  }, [fetchCards, isBrazeContentCardsExperimentEnabled, isBrazeInitialized]);

  useEffect(() => {
    if (
      didDispatchBrazeCards.current ||
      !brazeCards ||
      brazeCards.guideId !== guideId ||
      !(profileGuideItem || categoryGuideItem)
    ) {
      return;
    }

    didDispatchBrazeCards.current = true;
    setBrazeCards(null);

    if (isProfileGuideId) {
      actions.setBrazeProfileCards(guideId, brazeCards.items);
    } else {
      actions.setBrazeCategoryCards(guideId, brazeCards.items);
    }
  }, [
    actions,
    brazeCards,
    categoryGuideItem,
    guideId,
    isProfileGuideId,
    profileGuideItem,
  ]);

  useEffect(() => {
    if (cardValidationErrors.length) {
      actions.logContentCardFailures(cardValidationErrors);
    }
  }, [actions, cardValidationErrors]);
}
